<template>
  <div id="verify-payment-page">
    <div class="main-container p-3">Verifying ... Please wait.</div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["cart"]),
  },
  methods: {
    ...mapActions(["verifyPayment"]),
  },
  mounted() {
    this.verifyPayment({
      payment_status: this.$route.query.payment,
      order_id: this.$route.query.order,
      transaction_type: this.$route.query.transaction_type,
      cart: this.cart,
    });
  },
};
</script>

<style scoped>
#verify-payment-page {
}
</style>